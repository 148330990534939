import React, { useEffect } from 'react';

import ConfigBox from '../../components/Dashboard/ConfigBox';

import './Dashboard.scss';


const dashBoardLayout = [
  {
    header: 'Onboard New Tenant',
    description: 'Initiate onboarding process',
    redirect: 'new-tenant',
  },
  {
    header: 'Tenant Config',
    description: 'Download and upload XLSX',
    redirect: 'config-tenant',
  },
  {
    header: 'Release Deployment Tool',
    description: 'Tool to deploy releases',
    redirect: 'config-deployment',
  },
  {
    header: 'Schema Sync Tool',
    description: 'Sync schema in all tenant and across environment',
    redirect: 'config-schema',
  },
  {
    header: 'Update Version',
    description: 'Update app and config version',
    redirect: 'update-version',
  },
  {
    header: 'Decommission Tool',
    description: 'Decommission tenant',
    redirect: 'decommission',
  },
  //test
  {
    header: 'Buyer Decrypt',
    description: 'Decrypt Buyer Information',
    redirect: 'buyer-decrypt',
    testOnly: true,
  },
  {
    header: 'Pxnr Sync',
    description: 'Sync Phonex reseller',
    redirect: 'pxnr-sync',
    testOnly: true,
  },
  {
    header: 'Sync Settings',
    description: 'Sync settings and reference tables',
    redirect: 'sync-settings',
    testOnly: true,
  },
  //end test
  {
    header: 'Reseller Onboarding',
    description: 'Initiate reseller onboarding process',
    redirect: 'reseller-onboarding',
  },
  {
    header: 'Update Config',
    description: 'Update tenant configuration',
    redirect: 'update-config',
  },
  {
    header: 'Auction Onboarding',
    description: 'Initiate auction onboarding process',
    redirect: 'auction-onboarding',
  },
  {
    header: 'PO Onboarding',
    description: 'Initiate po onboarding process',
    redirect: 'po-onboarding',
  },
  {
    header: 'Cache Management',
    description: 'Cache Management',
    redirect: 'cache-management',
  },
  {
    header: 'Translation Automation',
    description: 'Translation Automation',
    redirect: 'translation-automation',
  },
  {
    header:'Splash Page Config',
    description:'Maintenance splash page configuration',
    redirect:'splash-config'
  }
];

export function Dashboard() {
  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' - Dashboard';
  }, []);

  return (
    <>
      <div className="dashboard grid-x grid-margin-x grid-margin-y">
        <h2 className="small-12">PhoneX ADMIN</h2>
        <div className="small-12 margin-top-3">
          {dashBoardLayout.map(({ header, description, redirect, testOnly }) =>
            !testOnly ? (
              <ConfigBox header={header} description={description} redirect={redirect} />
            ) : process.env.REACT_APP_ENV === 'test' ? (
              <ConfigBox header={header} description={description} redirect={redirect} />
            ) : (
              <></>
            )
          )}
          {/* <div className="cardx">
            <div className="cardx-section">
              <h4>Buyer Statistics</h4>
              <small>Check usage statistics of our SaaS</small>
              <p className="margin-top-1">
                <PrimaryButton disabled>
                  <ArrowForward />
                </PrimaryButton>
              </p>
            </div>
          </div>
          <div className="cardx">
            <div className="cardx-section">
              <h4>Tentant Statistics</h4>
              <small>Check statistics for a tenant</small>
              <p className="margin-top-1">
                <PrimaryButton disabled>
                  <ArrowForward />
                </PrimaryButton>
              </p>
            </div>
          </div>
          <div className="cardx">
            <div className="cardx-section">
              <h4>Server Status</h4>
              <small>Check on servers' status</small>
              <p className="margin-top-1">
                <PrimaryButton disabled>
                  <ArrowForward />
                </PrimaryButton>
              </p>
            </div>
          </div>
          <div className="cardx">
            <div className="cardx-section">
              <h4>IAM Reset</h4>
              <small>Request a IAM reset for tenant</small>
              <p className="margin-top-1">
                <PrimaryButton disabled>
                  <ArrowForward />
                </PrimaryButton>
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
