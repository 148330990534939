import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { getConfig } from './_lib/lib';
//AMPLIFY
import Amplify, { Auth } from 'aws-amplify';
// URL Template
import getAwsconfig from './aws-exports';
import Progress from './components/Progress/Progress';
import Login from './components/Login/AuthWrapper';
import ResetPassword from './components/Login/ResetPasswordFlow';
// ALL
import Theme from './Theme';
import TopMenu from './components/TopMenu/TopMenu';
//REDUX
import * as configActions from './redux/actions/configActions';
import * as contractsActions from './redux/actions/contractsActions';
import * as userActions from './redux/actions/userActions';
// LAYOUTS
import DashboardLayout from './layouts/layout-dashboard';
// VIEWS
import Homepage from './views/Homepage/Homepage';
import Dashboard from './views/Dashboard/Dashboard';
import ConfigTenant from './views/ConfigTenant/ConfigTenant';
import NewTenant from './views/NewTenant/NewTenant';
import { ConfigDeployment } from './views/ConfigDeployment/ConfigDeployment';
import { ConfigSchema } from './views/ConfigSchema/ConfigSchema';
import VersionToRelease from './views/ConfigDeployment/VersionToRelease';
import TestToVersioned from './views/ConfigDeployment/TestToVersioned';
import ReleaseToProd from './views/ConfigDeployment/ReleaseToProd';
import { MasterSchema } from './views/ConfigSchema/MasterSchemaSync';
import SlaveSchema from './views/ConfigSchema/SlaveSchemaSync';
import ManualUpload from './views/SchemaSync/ManualUpload';
import HorizontalSync from './views/SchemaSync/HorizontalSync';
import VerticalSync from './views/SchemaSync/VerticalSync';
import UpdateVersion from './views/UpdateVersion/UpdateVersion';
import { BuyerDecrypt } from './views/BuyerDecrypt/BuyerDecrypt';
import { Decommission } from './views/Decommission/Decommission';
import Clients from './views/Clients/Clients';
import ClientInfo from './views/ClientDetails/ClientDetails';
import Invoice from './views/Invoice/Invoice';
import BillingPeriodOrders from './views/BillingPeriodOrders/BillingPeriodOrders';
import InvoiceDetail from './views/InvoiceDetail/InvoiceDetail';
import { adminScreenURLs, clientSuccessURLS } from './ApiMapping';
import ResellerOnboarding from './views/ResellerOnboarding/ResellerOnboarding';
import Reports from './views/Reports/Reports';
import SyncSettings from './views/SyncSettings/SyncSettings';
import UpdateConfig from './views/UpdateConfig/UpdateConfig';
import PxnrSync from './views/PxnrSync/PxnrSync';
import RegistrationForm from './views/ResellerInvite/RegistrationForm';
import ClientForms from './views/ClientForms/ClientForms';
import UpdateClientForm from './views/ClientForm/ClientForm';
import AuctionOnboarding from './views/AuctionOnboarding/AuctionOnboarding';
import POOnboarding from './views/POOnboarding/POOnboarding';
import CacheManagement from './views/CacheManagement/CacheManagement';
import TranslationAutomation from './views/TranslationAutomation/TranslationAutomation';
import SplashConfig from './views/SplashConfig/SplashConfig';

// let called = 1;

const PrivateRoute = ({
  children,
  redux: {
    props: { userState },
  },
  ...rest
}: any) => {
  const location = useLocation();
  const history = useHistory();

  history.listen((location) => {
    // if (!location.search)
    window.scrollTo(0, 0);
  });

  const checkUserCanAccess = () => {
    const canAccessByAdmin =
      userState.role['PLATFORM_ADMIN'] && rest.canAccessBy === 'PLATFORM_ADMIN';
    const canAccessByFinance = userState.role['FINANCE'] && rest.canAccessBy === 'FINANCE';
    const canAccessByClientSuccess =
      userState.role['CLIENT_SUCCESS'] && rest.canAccessBy === 'CLIENT_SUCCESS';
    return canAccessByAdmin || canAccessByFinance || canAccessByClientSuccess;
  };

  const routeManager = () => {
    if (userState.isSignedIn && checkUserCanAccess()) {
      return <DashboardLayout isMenuOpened={rest.isMenuOpened}>{children}</DashboardLayout>;
    } else {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: { pathname: location.pathname }, search: location.search },
          }}
        />
      );
    }
  };

  return (
    <>
      <Route {...rest} render={() => routeManager()} />
    </>
  );
};

const HomeRoute = ({ children, ...rest }: any) => {
  const getRedirectionUrl = (location: any) => {
    if (rest.redux.props.userState.role['PLATFORM_ADMIN']) {
      return location.state && isAdminUrl(location.state.from.pathname)
        ? `${location.state.from.pathname}`
        : '/dashboard';
    } else if (rest.redux.props.userState.role['FINANCE']) {
      return location.state &&
        !isAdminUrl(location.state.from.pathname) &&
        !isClientSuccessUrl(location.state.from.pathname)
        ? `${location.state.from.pathname}`
        : '/clients';
    } else if (rest.redux.props.userState.role['CLIENT_SUCCESS']) {
      return location.state && isClientSuccessUrl(location.state.from.pathname)
        ? `${location.state.from.pathname}`
        : '/client-form';
    } else {
      return '/dashboard';
    }
  };

  const isAdminUrl = (pathName: string) => {
    return Object.values(adminScreenURLs).includes(pathName);
  };

  const isClientSuccessUrl = (pathName: string) => {
    return Object.values(clientSuccessURLS).includes(pathName);
  };

  return (
    <Route
      {...rest}
      render={({ location }: any) => {
        if (!rest.redux.props.userState.isSignedIn) {
          return (
            <Homepage>
              <Login />
            </Homepage>
          );
        }

        return (
          <Redirect
            to={{
              pathname: getRedirectionUrl(location),
              search: location.state ? location.state.search : '',
              state: { from: location.state ? location.state.from : '' },
            }}
          />
        );
      }}
    />
  );
};

const ResetPasswordRoute = ({ children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (
          <Homepage>
            <ResetPassword />
          </Homepage>
        );
      }}
    />
  );
};

const ResellerRegistrationForm = ({ children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return <RegistrationForm />;
      }}
    />
  );
};

const NewClientAccount = ({ children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return <UpdateClientForm isNewClient />;
      }}
    />
  );
};

const PxRouter = (props: any) => {
  return (
    <Theme redux={props}>
      <Router>
        <header className="cell small-12">
          <TopMenu />
        </header>
        <div className="cell small-12">
          <Switch>
            <HomeRoute path="/" exact={true} redux={props} />
            <ResellerRegistrationForm path="/register" exact={true} redux={props} />
            <NewClientAccount path="/new-client" exact={true} redux={props} />
            <PrivateRoute
              path="/dashboard"
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute
              path="/config-tenant"
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <ConfigTenant />
            </PrivateRoute>
            <PrivateRoute
              path="/new-tenant"
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <NewTenant />
            </PrivateRoute>
            <ResetPasswordRoute path="/reset-password" redux={props} />
            <PrivateRoute
              path="/config-deployment"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <ConfigDeployment />
            </PrivateRoute>
            <PrivateRoute
              path="/config-deployment/version-to-release"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <VersionToRelease />
            </PrivateRoute>
            <PrivateRoute
              path="/config-deployment/test-to-version"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <TestToVersioned />
            </PrivateRoute>
            <PrivateRoute
              path="/config-deployment/release-to-prod"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <ReleaseToProd />
            </PrivateRoute>
            <PrivateRoute
              path="/config-schema"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <ConfigSchema />
            </PrivateRoute>
            <PrivateRoute
              path="/config-schema/master"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <MasterSchema />
            </PrivateRoute>
            <PrivateRoute
              path="/config-schema/master/manual-upload"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <ManualUpload syncFor="master" />
            </PrivateRoute>
            <PrivateRoute
              path="/config-schema/master/horizontal-sync"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <HorizontalSync syncFor="master" />
            </PrivateRoute>
            <PrivateRoute
              path="/config-schema/master/vertical-sync"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <VerticalSync syncFor="master" />
            </PrivateRoute>
            <PrivateRoute
              path="/config-schema/slave"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <SlaveSchema />
            </PrivateRoute>
            <PrivateRoute
              path="/config-schema/slave/manual-upload"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <ManualUpload syncFor="slave" />
            </PrivateRoute>
            <PrivateRoute
              path="/config-schema/slave/horizontal-sync"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <HorizontalSync syncFor="slave" />
            </PrivateRoute>
            <PrivateRoute
              path="/config-schema/slave/vertical-sync"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <VerticalSync syncFor="slave" />
            </PrivateRoute>
            <PrivateRoute
              path="/update-version"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <UpdateVersion />
            </PrivateRoute>
            <PrivateRoute
              path="/buyer-decrypt"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <BuyerDecrypt />
            </PrivateRoute>
            <PrivateRoute
              path="/decommission"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <Decommission />
            </PrivateRoute>
            <PrivateRoute
              path={adminScreenURLs.resellerOnboarding}
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <ResellerOnboarding />
            </PrivateRoute>
            <PrivateRoute
              path="/sync-settings"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <SyncSettings />
            </PrivateRoute>
            <PrivateRoute
              path="/update-config"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <UpdateConfig />
            </PrivateRoute>
            <PrivateRoute
              path="/pxnr-sync"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <PxnrSync />
            </PrivateRoute>
            <PrivateRoute
              path="/auction-onboarding"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <AuctionOnboarding />
            </PrivateRoute>
            <PrivateRoute
              path="/po-onboarding"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <POOnboarding />
            </PrivateRoute>
            <PrivateRoute
              path="/cache-management"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <CacheManagement />
            </PrivateRoute>
            <PrivateRoute
              path={adminScreenURLs.translationAutomation}
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <TranslationAutomation />
            </PrivateRoute>
            <PrivateRoute
              path={adminScreenURLs.splashConfig}
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="PLATFORM_ADMIN">
              <SplashConfig/>
            </PrivateRoute>
            {/* clients */}
            <PrivateRoute
              path="/clients"
              exact
              redux={props}
              isMenuOpened={1}
              canAccessBy="FINANCE">
              <Clients />
            </PrivateRoute>
            <PrivateRoute
              path="/clients/:id"
              exact={true}
              redux={props}
              isMenuOpened={1}
              canAccessBy="FINANCE">
              <ClientInfo />
            </PrivateRoute>
            <PrivateRoute
              path="/invoices"
              exact={true}
              redux={props}
              isMenuOpened={1}
              canAccessBy="FINANCE">
              <Invoice />
            </PrivateRoute>
            <PrivateRoute
              path="/billing-period-orders/:id"
              exact={true}
              redux={props}
              isMenuOpened={1}
              canAccessBy="FINANCE">
              <BillingPeriodOrders />
            </PrivateRoute>
            <PrivateRoute
              path="/invoice/:id"
              exact={true}
              redux={props}
              isMenuOpened={1}
              canAccessBy="FINANCE">
              <InvoiceDetail />
            </PrivateRoute>
            <PrivateRoute
              path="/reports"
              exact={true}
              redux={props}
              isMenuOpened={1}
              canAccessBy="FINANCE">
              <Reports />
            </PrivateRoute>

            {/* CLIENT_SUCCESS */}
            <PrivateRoute
              path="/client-form"
              exact={true}
              redux={props}
              isMenuOpened={1}
              canAccessBy="CLIENT_SUCCESS">
              <ClientForms />
            </PrivateRoute>
            <PrivateRoute
              path="/client-form/:id"
              exact={true}
              redux={props}
              isMenuOpened={1}
              canAccessBy="CLIENT_SUCCESS">
              <UpdateClientForm />
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    </Theme>
  );
};

const loading = (
  <>
    <h3 className="text-center">Loading...</h3>
    <Progress />
  </>
);

function PhonexRouter(props: any) {
  useEffect(() => {
    async function fetchConfigAndUser() {
      try {
        let remoteConfig;
        if (props.configState && !props.configState.isLoaded) {
          remoteConfig = await getConfig();
          Amplify.configure(getAwsconfig(remoteConfig));
          props.configActions.configSet(remoteConfig);
        } else Amplify.configure(getAwsconfig(props.configState));

        const currentUser = await Auth.currentAuthenticatedUser();
        // console.log(currentUser)
        props.userActions.setUserAndFetchAuthorities(currentUser);
      } catch (error: any) {
        console.log(error);
        props.userActions.userStateSet({ isSignedIn: false });
      }
    }
    fetchConfigAndUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid-x">
      {props.configState.isLoaded && props.userState ? <PxRouter props={props} /> : loading}
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    userState: state.userState,
    configState: state.configState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    configActions: bindActionCreators(configActions, dispatch),
    contractsActions: bindActionCreators(contractsActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhonexRouter);
